import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import palette from "src/theme/palette";
import QuillView from "src/components/QuillView";
import { resolvePrimaryImageSrc } from "src/utils/imageLinks";
import { useSelector } from "react-redux";
import { SupportsWebp } from "src/slices/appSlice";

const GiftSummaryCard = ({ product, showImage = false }) => {
  const supportsWebp = useSelector(SupportsWebp);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        display: "flex",
        position: "relative",
        overflow: "hidden",
        border: `1px solid ${palette.grey[300]}`,
        "&::before": {
          content: '""',
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: "8px",
          backgroundColor: palette.primary.main,
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
          py: 2,
          pl: 3,
          pr: showImage ? 3 : 3,
          gap: 3,
        }}
      >
        <Box sx={{ flex: 1, minWidth: 0 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 500,
              color: "text.primary",
              mb: 1,
            }}
          >
            {product.name}
          </Typography>

          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 1 }}>
            {product.shortDescription}
          </Typography>

          {!showImage && <QuillView value={product.descriptionAsQuill} />}
        </Box>

        {showImage && (
          <Box
            sx={{
              width: 180,
              height: 180,
              flexShrink: 0,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: isMobile ? "center" : "flex-start",
              m: 1,
            }}
          >
            <Box
              component="img"
              src={resolvePrimaryImageSrc(
                "product",
                product.id,
                product.primaryImageFileNamePrefix,
                "md",
                supportsWebp ? "webp" : "jpg"
              )}
              alt={product.name}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 2,
              }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default GiftSummaryCard;
